<template>
  <nuxt-link
    :to="{
      name: 'course-areas-courseArea',
      params: {
        courseArea: props.courseArea.slug
      },
      query: filterQuery
    }"
    class="flex flex-col h-full transform border shadow-card hover:shadow-md hover:-translate-y-1 transition-all duration-300 ease"
  >
    <slot name="card-image">
      <div class="relative">
        <LazyImg
          v-if="props.courseArea.primaryImage"
          class="w-full object-cover aspect-[16/9]"
          src="/images/lz.png"
          :data-src="imageSrc"
          :alt="props.courseArea.primaryImageAlt"
        />
      </div>
    </slot>
    <div
      :class="[
        'p-4 pb-6 flex-grow',
        props.courseArea.color
          ? `border-t-6 border-brand-${props.courseArea.color}-300`
          : ''
      ]"
    >
      <slot name="card-title">
        <h2 v-if="courseArea.name" class="h5 leading-tight mb-2">
          {{ courseArea.name }}
        </h2>
      </slot>
      <p class="text-base font-bold text-brand-grey-600 mb-2">
        {{ count }} {{ pluralize('Courses', count) }}
      </p>

      <slot></slot>
    </div>
  </nuxt-link>
</template>

<script setup lang="ts">
import pluralize from 'pluralize'

import useFrontDoorApi from '~/composables/useFrontDoorApi'
import useHelpers from '~/composables/useHelpers'

interface CourseArea {
  color?: string
  primaryImage?: string
  primaryImageAlt?: string
  name?: string
  slug?: string
}

const { getImgPath } = useHelpers()
const props = withDefaults(
  defineProps<{
    courseArea: CourseArea
    count?: number
    filter?: string
  }>(),
  { count: 0, filter: '' }
)

const imageSrc = computed(() => {
  const { getImgUrl } = useFrontDoorApi()
  const path = getImgPath(props.courseArea?.primaryImage)
  return getImgUrl(path)
})

const filterQuery = computed(() => {
  return props.filter ? { filter: props.filter } : null
})
</script>
